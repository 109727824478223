<template>
  <div class="sckz-search-container">
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
    </a-range-picker>
    <m-select-more
      class="search_item"
      v-model="searchquery.appIdList"
      :allData="appList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="应用"
      @change="changeAppIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.sourceIdList"
      :allData="sourceList"
      :searchById="true"
      :hasIcon="false"
      :showId="true"
      label="广告源"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
    />

    <m-select-more
      class="search_item"
      v-model="searchquery.platIdList"
      :allData="platList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告平台"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.packageList"
      :allData="packageList"
      :searchById="true"
      :hasIcon="false"
      :showId="true"
      label="产品包名"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.productList"
      :allData="productList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="推广产品"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.osList"
      :allData="osList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="操作系统"
      :showSelectedPart="false"
      width="220px"
      :canSelectAll="false"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.typeList"
      :allData="typeList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="素材类型"
      :showSelectedPart="false"
      width="220px"
      :canSelectAll="true"
    />
    <div class="search_item">
      <a-input type="text" placeholder="标题、描述、素材ID" v-model.trim="searchquery.keywords"/>
    </div>
    <div class="search_item">
      <a-button type="primary" @click="handleSearch">查询</a-button>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { getDuration } from '@/utils/duration'
import { getRtbPlatformList } from '@/api/common'
import { mapState } from 'vuex'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      // 查询字段
      searchquery: {
        dateEnd: undefined,
        dateStart: undefined,
        appIdList: [],
        placeIdList: [],
        platIdList: [],
        sourceIdList: [],
        packageList: [],
        productList: [],
        osList: [],
        typeList: []
      }
    }
  },
  created () {
    const { endDate, startDate, end, start } = this.initDate(0, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.getAppList()
    this.getPlaceList()
    if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
      this.getPlatList()
    } else {
      this.getRtbPlatformList()
    }
    this.getSourceList()
    this.getProdAndpackageList()
    this.handleSearch()
  },
  computed: {
    ...mapState({
      adminUserId: (state) => state.user.adminUserId,
      role: (state) => state.user.roles[0]
    })
  },
  methods: {
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      if (ids.length === 0) {
        // 广告位无值，通过全部placelist查询广告源列表
        await this.getSourceListByAdplace(this.placeList.map((item) => item.id))
      } else {
        // 广告位选了，通过选中的place查询广告源列表
        this.getSourceListByAdplace(ids)
      }
    },
    // 获取所有广告平台
    async getRtbPlatformList () {
      const { data = [] } = await getRtbPlatformList()
      data.forEach((item) => {
        item.id = '' + item.id
      })
      this.platList = data
    },
    handleSearch () {
      this.$emit('handleSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less" scoped>
.sckz-search-container {
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  .search_item {
    width: 260px;
    margin: 5px;
  }
}
</style>
